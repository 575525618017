module.exports = {
  default: {
    colors: {
      tretornBlue: '#12284C',
      tretornYellow: '#F0AB00',
      tretornGrey: '#EBEEF2',
      hoverAddToCart: '#FFB807',
      black: '#1A1A1A',
      hoverPrimaryButton: '#091425',
      text: '#575757',
      grey: '#ADADAD',
      disabled: '#CCCBCB',
      lightGrey: '#E3E3E3',
      lightBackground: '#F9F9F8',
      offWhite: '#EFEFEF',
      white: '#FFFFFF',
      tretornBeige: '#E1E2D5',
      darkTretornBeige: '#D4D5C3',
      brandColor: '#A0BBBB',
      discount: '#E20101',
      error: '#FF0404'
    },
    fontWeights: {
      light: 300,
      regular: 400,
      semibold: 700
    },
    breakpoints: {
      xs: '28rem',
      sm: '48rem',
      md: '64rem',
      lg: '80rem',
      xl: '90rem'
    },
    fontSizes: [
      '12px',
      '14px',
      '16px',
      '20px',
      '24px',
      '32px',
      '48px',
      '64px',
      '72px'
    ],
    fonts: {
      primary: 'Avenir Next',
      primaryFallback: 'Nunito Sans',
      secondary: 'HCo Chronicle Display'
    }
  },
  alt: {
    colors: {
      blue: 'blue',
      main: 'black'
    }
  }
};
